<template>
  <div class="m-page-header">
    <div v-for="(item, index) in pageTitle" :key="index" :class="{ 'only-title': item.isavtive }">
      <span @click="hanldActive(index)">{{ item.text }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pageTitle: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    function hanldActive(index) {
      context.emit('upDate', index);
    }
    return {
      hanldActive,
    };
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/tool';
.m-page-header {
  background-color: $lightGray;
  height: 60px;
  display: flex;
  > div {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 16px;
    span {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      min-width: 120px;
      cursor: pointer;
    }
  }
  .only-title {
    box-sizing: border-box;
    border-top: 3px solid $theme;
    background: #fff;
  }

  .link-header {
    display: flex;
    align-items: center;
    .back-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 60px;
      background-color: #eee;
      color: #999;
      font-size: 28px;
      margin-right: 16px;
    }
  }
}
</style>
