import request from '@/tools/request';
export function saveBase(data) {
  return request({
    url: `/imagesearch-Config-saveBase.html`,
    method: 'post',
    data,
  });
}
export function SaveCategory(data) {
  return request({
    url: `/imagesearch-Category-SaveCategory.html`,
    method: 'post',
    data,
  });
}
export function Config(data) {
  return request({
    url: `/imagesearch-Config-base.html`,
    method: 'get',
    params: data,
  });
}
export function CategoryList(data, loading) {
  return request({
    url: `/imagesearch-Category-list.html`,
    method: 'get',
    params: data,
    loading,
  });
}
