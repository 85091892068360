<template>
  <div class="view">
    <m-form-skeleton :loading="fromLoading">
      <div class="serach_icon">搜索图标</div>
      <div class="icon_list">
        <span
          v-for="(item, index) in iconList"
          :key="index"
          :class="{ icon_active: item.isavtive }"
          @click="tabIcon(index)"
          ><i :class="item.iconClass"></i
        ></span>
      </div>
      <div class="serach_icon">图标描述</div>
      <div class="form">
        <el-input
          type="textarea"
          :rows="5"
          placeholder="请输入内容"
          v-model="formInput.icon_desc"
        />
      </div>
      <el-form :model="formInput" :rules="rules" ref="ruleForm">
        <div v-if="isDev">
          <div class="serach_icon">图标位置（PC端）</div>
          <div class="icon_list">
            <div class="input_from">
              <span>距右侧边距</span>
              <span>
                <el-form-item prop="icon_pc_position">
                  <el-input
                    v-model="formInput.icon_pc_position"
                    size="medium"
                    placeholder="建议50-180之间"
                  >
                    <template #suffix> px </template>
                  </el-input>
                </el-form-item>
              </span>
            </div>
          </div>
        </div>
        <div v-if="isDev">
          <div class="serach_icon">图标位置（移动端）</div>
          <div class="icon_list">
            <div class="input_from">
              <span>距右侧边距</span>
              <span>
                <el-form-item prop="icon_wap_position">
                  <el-input
                    v-model="formInput.icon_wap_position"
                    size="medium"
                    placeholder="建议10-120之间"
                  >
                    <template #suffix> px </template>
                  </el-input>
                </el-form-item>
              </span>
            </div>
          </div>
        </div>

        <div class="serach_icon">
          华为token
          <el-tooltip content="请联系华为获取配置" placement="right" effect="light">
            <i class="el-icon-question" style="font-size: 16px; margin-left: 5px"></i>
          </el-tooltip>
        </div>
        <div class="icon_list">
          <div class="input_from">
            <span>
              <el-form-item prop="authorization">
                <el-input v-model="formInput.authorization" size="medium" placeholder="请输入内容">
                </el-input>
              </el-form-item>
            </span>
          </div>
        </div>
        <div class="serach_icon">
          华为url
          <el-tooltip content="请联系华为获取配置" placement="right" effect="light">
            <i class="el-icon-question" style="font-size: 16px; margin-left: 5px"></i>
          </el-tooltip>
        </div>
        <div class="icon_list">
          <div class="input_from">
            <span>
              <el-form-item prop="authurl">
                <el-input v-model="formInput.authurl" size="medium" placeholder="请输入内容">
                </el-input>
              </el-form-item>
            </span>
          </div>
        </div>
        <div class="serach_icon">
          华为AppID
          <el-tooltip content="请联系华为获取配置" placement="right" effect="light">
            <i class="el-icon-question" style="font-size: 16px; margin-left: 5px"></i>
          </el-tooltip>
        </div>
        <div class="icon_list">
          <div class="input_from">
            <span>
              <el-form-item prop="appid">
                <el-input v-model="formInput.appid" size="medium" placeholder="请输入内容">
                </el-input>
              </el-form-item>
            </span>
          </div>
        </div>
        <div v-if="isDev">
          <div class="serach_icon">
            页面dom元素位置
            <el-tooltip content="以图搜索功能在页面上出现的位置" placement="right" effect="light">
              <i class="el-icon-question" style="font-size: 16px; margin-left: 5px"></i>
            </el-tooltip>
          </div>
          <div class="icon_list">
            <div class="input_from">
              <span>
                <el-form-item prop="dom_id">
                  <el-input v-model="formInput.dom_id" size="medium" placeholder="请输入内容">
                  </el-input>
                </el-form-item>
              </span>
            </div>
          </div>
        </div>
        <div class="serach_icon">
          <span>搜索备注</span>
          <span
            ><el-tooltip
              content="搜索图片的备注内容，如:图片大小不超过10M"
              placement="right"
              effect="light"
            >
              <i class="el-icon-question" style="margin-left: 5px"></i>
            </el-tooltip>
          </span>
        </div>
        <div class="form">
          <el-form-item prop="remark">
            <el-input
              type="textarea"
              :rows="5"
              placeholder="请输入内容"
              v-model="formInput.remark"
            />
          </el-form-item>
        </div>
      </el-form>
    </m-form-skeleton>
  </div>
  <div class="saveBtn" v-show="!fromLoading">
    <el-button type="primary" @click="save" :loading="loading">保存</el-button>
  </div>
</template>

<script>
import { ref } from 'vue';
import { saveBase, Config } from '../../api/BasicSetting';
import { ElMessage } from 'element-plus';
import { useRoute } from 'vue-router';

export default {
  name: 'Index',
  setup() {
    const iconList = ref([
      {
        iconClass: 'iconfont-imageserach icon-xiangji3',
        isavtive: true,
      },
      {
        iconClass: 'iconfont-imageserach icon-tubiaozhizuomoban',
        isavtive: false,
      },
      {
        iconClass: 'iconfont-imageserach icon-xiangji1',
        isavtive: false,
      },
      {
        iconClass: 'iconfont-imageserach icon-xiangji',
        isavtive: false,
      },
      {
        iconClass: 'iconfont-imageserach icon-xiangji2',
        isavtive: false,
      },
    ]);
    const rules = ref({
      icon_pc_position: [{ required: true, message: '请输入pc端右侧边距', trigger: 'blur' }],
      icon_wap_position: [{ required: true, message: '请输入移动端右侧边距', trigger: 'blur' }],
      authorization: [{ required: true, message: '请输入华为token', trigger: 'blur' }],
      appid: [{ required: true, message: '请输入华为AppID', trigger: 'blur' }],
      dom_id: [{ required: true, message: '请输入页面dom元素位置', trigger: 'blur' }],
      authurl: [{ required: true, message: '请输入华为url', trigger: 'blur' }],
      remark: [{ required: true, message: '请输入搜索备注', trigger: 'blur' }],
    });
    const loading = ref(false);
    const fromLoading = ref(true);
    const route = useRoute();
    const isDev = ref(route.query.dev); //是否显示dom配置
    const formInput = ref({
      icon: iconList.value[0].iconClass, //图标
      icon_desc: '', //图标描述
      authurl: '', //华为url
      icon_pc_position: '', //pc距右侧边距
      icon_wap_position: '', //移动距右侧边距
      authorization: '', //华为token
      appid: '', //华为AppID
      dom_id: '', //页面dom 元素 位置
      remark: '', //搜索备注
    });
    const tabIcon = index => {
      iconList.value.map(res => {
        res.isavtive = false;
      });
      iconList.value[index].isavtive = true;
      formInput.value.icon = iconList.value[index].iconClass;
    };
    const getConfig = async () => {
      fromLoading.value = true;
      const { data } = await Config();
      if (data.param.icon) {
        iconList.value.map(res => {
          res.isavtive = false;
          if (data.param.icon === res.iconClass) {
            res.isavtive = true;
          }
        });
        data.param.appid = data.appid;
        data.param.authorization = data.authorization;
        formInput.value = data.param;
      }
      fromLoading.value = false;
    };
    const ruleForm = ref();
    const save = () => {
      ruleForm.value.validate(valid => {
        if (valid) {
          loading.value = true;
          saveBase(formInput.value)
            .then(() => {
              loading.value = false;
              ElMessage.success({
                message: '操作成功',
                type: 'success',
              });
              getConfig();
            })
            .catch(() => {
              loading.value = false;
            });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    };
    return {
      iconList,
      rules,
      loading,
      fromLoading,
      isDev,
      getConfig,
      ruleForm,
      save,
      formInput,
      tabIcon,
    };
  },
  mounted() {
    this.getConfig();
  },
};
</script>

<style scoped lang="scss">
@import '../../statics/scss/iconfont';
.view {
  padding: 15px;
  .serach_icon {
    padding: 20px 0;
  }
  .icon_list {
    background-color: #f6f6f6;
    padding: 20px;
    box-sizing: border-box;
    .el-form-item {
      margin: 0;
    }
    .input_from {
      display: flex;
      align-items: center;
      > span:first-child {
        margin-right: 15px;
      }
    }
    > span {
      display: inline-block;
      width: 46px;
      height: 46px;
      border-radius: 2px;
      border: solid 1px #d7dde3;
      background-color: #fff;
      margin-right: 13px;
      text-align: center;
      line-height: 46px;
      cursor: pointer;
      i {
        color: #666;
        font-size: 30px;
      }
    }
    .icon_active {
      border: 1px solid #666698;
      i {
        color: #666698;
      }
    }
  }
  ::v-deep(textarea) {
    width: 500px;
  }
}
.saveBtn {
  background-color: #f6f6f6;
  padding: 15px;
  box-sizing: border-box;
  button {
    width: 187px;
  }
}
</style>
